import axios from 'axios'
import { PINATA_JWT_TOKEN } from './constants'
import { ItemUiMetadata } from './types'

export const getIpfsHashFromJson = async (data: ItemUiMetadata) => {
  try {
    const res = await axios.post(
      'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      JSON.stringify(data),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${PINATA_JWT_TOKEN}`
        }
      }
    )
    return res.data.IpfsHash
  } catch (error) {
    throw new Error('Error getting IPFS Hash from JSON')
  }
}

export const getIpfsHashFromFile = async (file: string | Blob) => {
  try {
    if (!file) throw new Error('No file for IPFS upload!')

    const formData = new FormData()
    formData.append('file', file)

    const config = {
      maxBodyLength: Infinity,
      headers: {
        // @ts-ignore _boundary
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: `Bearer ${PINATA_JWT_TOKEN}`
      }
    }
    const res = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, config)
    return res.data.IpfsHash
  } catch (error) {
    throw new Error('Error getting IPFS Hash from File')
  }
}
