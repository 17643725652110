import { useEffect, useState } from 'react'
import axios from 'axios'

import { payTransferFee as payEvmFee, transferNFT as transferEvmNft } from '../../utils/EVM/EvmService'
import { transferNft as transferCkbNft } from '../../utils/CKB/CkbService'
import { useWeb3React } from '@web3-react/core'
import './transfer.scss'
import { CHAIN_KEYS, DEAD_ADDRESS, INDEXED_DB_NAME, NFT_STANDARDS, USER_DB_STORE_NAME } from '../../utils/constants'
import { Snackbar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAuth } from '../../context/authContext'
import NationDB from '../../utils/IndexedDB'

const TransferModal = (props: { afterTransferCallback: any }) => {
  const { afterTransferCallback } = props
  const { state } = useAuth()
  const { user } = state
  const { provider } = useWeb3React()

  const [item, setItem] = useState<{ [key: string]: any } | null>(null)
  const [amountOwned, setAmountOwned] = useState(1n)
  const [transferAmount, setTransferAmount] = useState(1n)
  const [transferAddress, setTransferAddress] = useState('')
  const [feePaid, setFeePaid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [itemTransferType, setItemTransferType] = useState('Transfer')
  const [burnNft, setBurnNft] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [store, setStore] = useState<typeof NationDB | null>(null)
  const [type, setType] = useState<'item' | 'collection'>('item')

  useEffect(() => {
    let isMounted = true

    async function setupStore() {
      if (!isMounted) return

      const newStore = await NationDB.initDB(USER_DB_STORE_NAME)

      setStore(newStore)
    }

    if (!store) setupStore()

    return () => {
      isMounted = false
    }
  }, [])


  useEffect(() => {
    document?.querySelectorAll('button[data-target="#transfer-modal"]')?.forEach(link => link?.addEventListener('click', async event => {
      if (!event?.target) return
      const item = JSON.parse((event.target as HTMLButtonElement)?.dataset?.item ?? '')

      if (!item) return
      const standard = item.standard || item?.collectionData?.standard
      const paidFee = await store?.getData<boolean>('PaidFee') || false

      if ((event.target as HTMLButtonElement)?.dataset?.burn) {
        setBurnNft(true)
        setItemTransferType('Burn')
      } else {
        setItemTransferType('Transfer')
        setBurnNft(false)
      }

      if (item?.address) setType('collection')

      item.version = Number(item?.version) || Number(item?.collectionData?.version)
      item.address = item?.itemCollection ?? item?.address

      setItem(item)

      const isCkb = item?.chain === CHAIN_KEYS.ckb || item?.collectionData?.chain === CHAIN_KEYS.ckb
      if (paidFee === true || isCkb) setFeePaid(true)

      if (standard === NFT_STANDARDS.erc1155 && !!item?.owners?.length) {
        const getUserAmount = item.owners.filter((owner: { address: string }) => owner.address.toLowerCase() === user?.address?.toLowerCase()).reduce((previous: string | number, current: { amount: string | number | bigint | boolean }) => BigInt(previous) + BigInt(current?.amount ?? 0), 0n)
        if (!getUserAmount) return

        setAmountOwned(getUserAmount)
      }
    }))
  }, [user])

  const handlePayFee = async (event: any) => {
    event.preventDefault()

    setLoading(true)
    try {
      const paidFee = await payEvmFee(await provider?.getSigner())

      if (!paidFee) throw new Error('Error validating Fee was paid!')

      store?.updateData('PaidFee', true)
      setFeePaid(paidFee)
    } catch (error) {
      console.error('Handle Transfer Error::', error)
      setOpenSnackbar(true)
      setSnackBarMessage('Error paying fee!')
    }

    setLoading(false)
  }

  const handleTransfer = async (event: { preventDefault: () => void }) => {
    if (!user) throw new Error('User not detected!')

    const transferTo = burnNft ? DEAD_ADDRESS : transferAddress

    event.preventDefault()

    if (!transferTo || transferTo === '') return

    if (!item) throw new Error('Error getting item details!')

    setLoading(true)

    try {
      let updateData
      let signer

      if (provider && 'getSigner' in provider) signer = await provider?.getSigner()

      if (item?.chain !== CHAIN_KEYS.ckb && signer) {
        await transferEvmNft(
          item?.address,
          item?.tokenId,
          user?.address,
          transferTo,
          transferAmount,
          item.standard,
          item?.version,
          signer
        )

        updateData = {
          ...item,
          transferAmount
        }
      } else {
        updateData = await transferCkbNft(
          item as ItemUiMetadata,
          {
            activeWallet: user.activeWallet,
            address: user?.addresses.find((addressObject: { [key: string]: string }) => addressObject.chain === CHAIN_KEYS.ckb).address
          },
          {
            transferTo,
            amount: transferAmount,
            burn: burnNft,
            type,
          },
          signer,
        )
      }

      if (!updateData) {
        setLoading(false)
        return
      }

      setOpenSnackbar(true)
      setSnackBarMessage('Transfer Success!')

      setFeePaid(false)
      setTransferAddress('')

      document.getElementById('Transfer-Close')?.click()

      await axios.post(`/update/${item.address}/${item?.tokenId ?? ''}`, {
        item,
        newItemData: {
          ...updateData,
          owner: transferTo
        },
      })

      if (afterTransferCallback) await afterTransferCallback()

    } catch (error) {
      console.error('Handle Transfer Error::', error)
      setOpenSnackbar(true)
      setSnackBarMessage('Transfer Failed!')
      setLoading(false)
    }
  }

  function verifyAmount(inputValue: any) {
    if (!isNaN(inputValue) && BigInt(inputValue) <= amountOwned) setTransferAmount(inputValue)
  }

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') return
    setOpenSnackbar(false)
  }

  return (
    <div id='transfer-modal' className='modal fade' >
      <div className='modal-dialog dialog-animated' style={{ maxWidth: '100%', display: 'grid' }}>
        <div className='modal-content container' style={{ alignSelf: 'center' }}>
          <h2 className='modal-header text-center' data-dismiss='modal'>
            <strong><em>{itemTransferType}</em></strong> {item?.name !== '' ? item?.name : 'NFT'}
          </h2>
          <div id='Transfer'>
            <div className={`item-details ${item?.tokenId ? 'has-tokenId' : ''}`}>
              <h4>Contract Address</h4>
              <span className='collection-address'>{item?.address}</span>
              {item?.tokenId && <>
                <h4>Token ID</h4>
                <span>{item.tokenId}</span>
              </>}
            </div>

            <hr />

            <form onSubmit={handleTransfer}>
              {!burnNft && <span style={{ gridColumn: item?.standard === NFT_STANDARDS.erc1155 ? '1' : '1/-1' }}>
                <label htmlFor='transferAddress'><h5><strong>Address to Transfer NFT to</strong></h5></label>
                <input type='text' name='transferAddress' id='transferAddress' value={transferAddress} onChange={event => setTransferAddress(event?.target?.value)} />
              </span>}

              {item?.standard === NFT_STANDARDS.erc1155 && <span>
                <label htmlFor='transferAmount'>
                  <h5>Amount <small>(Max: {amountOwned.toString()})</small></h5>
                </label>
                <input type='text' name='transferAmount' id='transferAmount' placeholder='1' onChange={event => verifyAmount(event.target.value)} value={transferAmount.toString()} disabled={amountOwned <= 1n} />
              </span>}

              <div className={`button-wrapper ${loading ? ' loading' : ''}`}>
                <button data-dismiss='modal' className='btn btn-solid-warn' id='Transfer-Close' onClick={() => setLoading(false)}>Close</button>
                {!burnNft && !feePaid ?
                  <button onClick={event => handlePayFee(event)} className='btn bg-text spinner'><span>Pay Transfer Fee</span></button>
                  : <button type='submit' className='btn bg-text spinner'><span>{itemTransferType} NFT</span></button>
                }
              </div>
            </form>

            {!burnNft && <p style={{ marginTop: 0 }}>
              <em>*There are 2 transactions for a Transfer on EVM</em> <br />
              <em>**Layer 1 CKB fee is part of the Transfer Transaction and costs a bit more because of Cell requirements</em>
            </p>}
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <span onClick={event => handleClose(event, 'close')}>
            <IconButton size='small' aria-label='close' color='inherit'>
              <CloseIcon fontSize='small' />
            </IconButton>
          </span>
        }
      />
    </div>
  )
}

export default TransferModal
