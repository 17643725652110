import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { getRenderReadyRoyalty, prettyCommaFormat } from '../../utils/helpers'
import { Analytics as IconAnalytics } from '@styled-icons/material/Analytics'
import { Storefront } from '@styled-icons/material/Storefront'
import AuthorAvatar from '../Author/AuthorAvatar'
import { NFT_STANDARDS } from '../../utils/constants'

import './analytics.scss'

const Analytics = (props: { data: any }) => {
  const { data } = props

  const [title, setTitle] = useState('')

  const [totalVolume, setTotalVolume] = useState()
  const [lowestPriceMarket, setLowestPriceMarket] = useState()
  const [lowestPriceAuction, setLowestPriceAuction] = useState()
  const [highestPriceMarket, setHighestPriceMarket] = useState()
  const [highestPriceAuction, setHighestPriceAuction] = useState()
  const [listedPercent, setListedPercent] = useState(0)
  const [listedItems, setListedItems] = useState()
  const [totalItemCount, setTotalItems] = useState()
  const [ownerCount, setOwnerCount] = useState()
  const [uniqueOwnerCount, setUniqueOwnerCount] = useState(0)
  const [soldCount, setSoldCount] = useState(0)
  const [activeSales, setActiveSales] = useState({ market: 0, auction: 0 })
  const [totalItemsMinted, setTotalItemsMinted] = useState()
  const [nftStandard, setNftStandard] = useState('')
  const [marketActive, setMarketActive] = useState(false)

  useEffect(() => {
    if (!data) return

    setNftStandard(data?.standard ?? '')

    function checkMarketStats(analyticsData: { [key: string]: any }) {
      const valuesToCheck = [
        analyticsData.activeSales.auction,
        analyticsData.activeSales.market,
        analyticsData?.lowestPrice.market,
        analyticsData?.lowestPrice.auction,
        analyticsData?.highestPrice.market,
        analyticsData?.highestPrice.auction,
        analyticsData?.listedItems,
        analyticsData?.soldCount,
        analyticsData?.totalVolume,
        analyticsData?.listedPercent
      ]

      const stats = valuesToCheck.some(value => value > 0)

      setMarketActive(stats)
    }

    async function fetchAnalytics() {
      try {
        const collectionAddress = (data?.address ?? data?.itemCollection ?? '').join(',')
        const queryParams = new URLSearchParams(`collectionAddress=${collectionAddress}`)

        if (data?.tokenId) queryParams.set('tokenId', data.tokenId)

        const { data: analyticsData } = await axios.get(`/analytics/collection?${queryParams.toString()}`)

        setTotalVolume(analyticsData?.totalVolume)
        setLowestPriceMarket(analyticsData?.lowestPrice.market)
        setLowestPriceAuction(analyticsData?.lowestPrice.auction)
        setHighestPriceMarket(analyticsData?.highestPrice.market)
        setHighestPriceAuction(analyticsData?.highestPrice.auction)
        setListedItems(analyticsData?.listedItems)
        setTotalItems(analyticsData?.totalItems)
        setListedPercent(analyticsData?.listedPercent)
        setOwnerCount(analyticsData?.ownerCount)
        setUniqueOwnerCount(analyticsData?.uniqueOwnerCount)
        setSoldCount(analyticsData?.soldCount)
        setActiveSales({
          auction: analyticsData.activeSales.auction,
          market: analyticsData.activeSales.market
        })
        setTotalItemsMinted(analyticsData?.totalUniqueItems)

        checkMarketStats(analyticsData)
      } catch (error) {
        console.error('Error fetching analytics', error)
      }
    }

    fetchAnalytics()

    if (data?.tokenId) setTitle('Item Statistics')
    else setTitle('Collection Statistics')
  }, [data])

  return (
    <div id="Analytics">
      {data?.standard !== NFT_STANDARDS.spore && marketActive && <div className="market">
        <IconAnalytics size={50} color='var(--primary-color)' className='icon' />
        <h4>Market Stats</h4>

        <dl>
          {(activeSales.market > 0 || activeSales.auction > 0) && <span>
            <dt>Active Sale{activeSales.market + activeSales.auction > 1 ? 's' : ''}</dt>
            {activeSales.market > 0 && <dd>{activeSales.market} (Sale)</dd>}
            {activeSales.auction > 0 && <dd>{activeSales.auction} (Auction)</dd>}
          </span>}

          {(lowestPriceMarket || lowestPriceAuction) && <span>
            <dt>Lowest Price{lowestPriceMarket && lowestPriceAuction ? 's' : ''}</dt>
            {lowestPriceMarket && <dd>{prettyCommaFormat(lowestPriceMarket, 2)} pCKB (Sale)</dd>}
            {lowestPriceAuction && <dd>{prettyCommaFormat(lowestPriceAuction, 2)} pCKB (Auction)</dd>}
          </span>}

          {(highestPriceMarket || highestPriceAuction) && <span>
            <dt>Highest Price{highestPriceMarket && highestPriceAuction ? 's' : ''}</dt>
            {highestPriceMarket && <dd>{prettyCommaFormat(highestPriceMarket, 2)} pCKB (Sale)</dd>}
            {highestPriceAuction && <dd>{prettyCommaFormat(highestPriceAuction, 2)} pCKB (Auction)</dd>}
          </span>}

          {totalVolume && <span>
            <dt>Total Volume</dt>
            <dd>{prettyCommaFormat(totalVolume, 2)} pCKB</dd>
          </span>}

          {listedPercent > 0 && <span>
            <dt>Items Listed</dt>
            <dd>{listedPercent?.toFixed(2)}% ({listedItems}/{totalItemCount})</dd>
          </span>}

          {soldCount && <span>
            <dt>NFTs Sold</dt>
            <dd>{soldCount}</dd>
          </span>}
        </dl>
      </div>}

      <div className="nft">
        <Storefront size={50} color='var(--primary-color)' />
        <h4>General Stats</h4>

        <dl>
          {data?.royalty && data?.royalty > 0 && <span>
            <dt>Collection Royalty</dt>
            <dd>{getRenderReadyRoyalty(data)}%</dd>
          </span>}

          {uniqueOwnerCount > 0 && <span>
            <dt>Owners</dt>
            <dd>{uniqueOwnerCount}</dd>
          </span>}

          {totalItemsMinted && <span>
            <dt>Total Items Minted</dt>
            <dd>{totalItemsMinted}</dd>
          </span>}

          {nftStandard !== '' && <span>
            <dt>Contract Standard</dt>
            {/* @ts-ignore */}
            <dd>{nftStandard}</dd>
          </span>}

          {data?.creator && <span>
            <dt>Collection Minter</dt>
            <dd><AuthorAvatar author={data?.creator} displayImage={false} /></dd>
          </span>}

          {!!data?.owner && <span>
            <dt>Collection Owner</dt>
            <dd><AuthorAvatar author={data?.owner} displayImage={false} /></dd>
          </span>}
        </dl>
      </div>
    </div>)
}

export default Analytics
