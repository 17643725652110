import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useAuth } from '../../context/authContext'
import axios from 'axios'
import DOMPurify from 'dompurify'
import { CircularProgress } from '@mui/material'

import NFT from '../Item/NftCard'
import MediaWrapper from '../Media/MediaWrapper'
import {
  CHAIN_KEYS,
  NERVAPE_GEAR_CLUSTER_IDS,
  NETWORK,
} from '../../utils/constants'

// @ts-ignore
import NervapeBanner from '../../assets/img/nervape-gear-nervos-ckb-layer-1.webp'

const GroupedCollections = (props: any) => {
  const { collection } = useParams<{ collection: string }>()
  const { state } = useAuth()
  const { user } = state
  const { account } = useWeb3React()

  const [collections, setCollections] = useState<Array<{ [key: string]: any }>>([])
  const [noMoreItems, setNoMoreItems] = useState(false)
  const [loading, setLoading] = useState(false)

  const name = useRef(getName(collection))
  const thumbnail = useRef()
  const bannerImage = useRef(getBannerImage(collection))
  const description = useRef(getDescription(collection))
  const brandOwner = useRef()

  useEffect(() => {
    setLoading(true)

    const addressesToFetch = getCollectionAddressFromUri(collection)

    const groupedData: Array<{ [key: string]: any }> = []
    const groupedCollections: Array<{ [key: string]: any }> = []


    async function getEachCollectionsItems() {
      try {
        const xlScreen = window.innerWidth >= 1620
        const collectionsLength = addressesToFetch.length
        let count = 1


        for await (const address of addressesToFetch) {
          const pageLimit = xlScreen ? 6 : 4
          const queryParams = new URLSearchParams(`pageLimit=${pageLimit}`)

          if (xlScreen && collectionsLength % 2 === 1
            && count === collectionsLength) queryParams.set('pageLimit', '12')

          const { data } = await axios.get(`/collection/${address}?${queryParams.toString()}`)

          groupedData.push(data)

          ++count
        }

        for (const item of groupedData) {
          groupedCollections.push(item.collection)
        }

        setCollections(groupedCollections)

        // REMOVE ANY COLLECTIONS MARKED isHidden
        // Else continue and setItems to our collections then we map them below
        // let allMatch = groupedData.every(obj => obj.collection.owner.address === firstOwner)
        // let isHidden = true

        // allMatch = groupedData.every(obj => obj.collection.isHidden !== true)
        // groupedCollections = groupedCollections.map(data => ({
        // }))
        // for (const item of groupedCollections) {
        // }

        setLoading(false)
      } catch (error) {
        console.error('Error fetching Collection', error)
        setLoading(false)
        setCollections([...collections])
        setNoMoreItems(true)
      }
    }

    getEachCollectionsItems()

  }, [collection])

  function getCollectionAddressFromUri(collectionName: string): Array<`0x${string}`> {
    switch (collectionName) {
      case 'nervape-gear':
        return NERVAPE_GEAR_CLUSTER_IDS?.[NETWORK]
      default:
        return ['0x0']

    }
  }

  function getName(collectionName: string) {
    switch (collectionName) {
      case 'nervape-gear':
        return 'Nervape Gear'
      default:
        return ''
    }
  }

  function getDescription(collectionName: string) {
    switch (collectionName) {
      case 'nervape-gear':
        return 'Accessorize and create your unique Nervape today with Nervape Gear!<br /><br />Check out the <a href="https://www.nervape.com/nervapes" target="_blank">Nervape Gallery</a> to view the entire Nervape collection with a filtered breakdown.'
      default:
        return ''
    }
  }

  function getBannerImage(collectionName: string) {
    switch (collectionName) {
      case 'nervape-gear':
        return NervapeBanner
      default:
        return ''
    }
  }

  return (
    <>
      <section id="GroupedCollections" className='collection-details'>
        {bannerImage.current && <div className='banner'>
          <MediaWrapper
            src={bannerImage.current}
            alt={`${name.current} on imagiNation by Nervos Nation built on Nervos CKB`}
          />

          {name.current && <h1 className='sr-only'>{name.current}</h1>}
        </div>}

        {thumbnail?.current && <MediaWrapper src={thumbnail.current} alt={`${name.current} on imagiNation by Nervos Nation built on Nervos CKB`} />}

        <div className='details'>
          {description.current
            && <p
              className='description'
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description.current?.replace(/\r\n/g, '<br>')) }}
            />
          }

          <div className='share'>
            <h5 className='sr-only'>Share</h5>
            <a
              className='btn'
              href={`https://x.com/intent/tweet?url=${window.location.href}&text=Check out this Project: ${name?.current ?? ''} on imagiNation!`}
              target='_blank' rel="noopener">
              <svg
                style={{ width: '40px', height: '40px' }}
                width="1200" height="1227" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="#fff" />
              </svg>
            </a>
          </div>
        </div>

        {collections?.length ? <div className='collections'>
          <h2>Explore {name?.current ?? 'Collections'}</h2>
          {collections.map((collection, index) => (
            <div key={index} className='collection'>
              <h3 className='text-center' style={{ color: '#000' }}>{collection?.name?.split(' ').slice(1).join(' ')}</h3>

              <div className='grid items explore-items' id={`collection_${index}`}>
                {collection.items?.map((item: ItemUiMetadata, itemIndex: React.Key | null | undefined) => (
                  <NFT {...props} item={item} key={itemIndex} className='item' displayPrice={false} />
                ))}
              </div>

              <NavLink to={`/collection/${collection.address}`} className='btn branded btn-block mt-3'>{collection?.name ? `Explore All ${collection.name}` : 'Explore Entire Collection'}</NavLink>
            </div>
          ))} </div>
          : loading ? <CircularProgress className='loader d-block mx-auto mb-4' /> : <p className='text-center mt-n3'>No Items Found.</p>}
      </section>
    </>
  )
}

export default GroupedCollections
