import React, { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// hearder, footer, modalsearch, modalmenu, scrollup
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ModalSearch from '../components/Modal/ModalSearch'
import Scrollup from '../components/Scrollup/Scrollup'

function Layout (props) {
  const [isLogin, setIsLogin] = useState(false)
  const location = useLocation()

  const pageId = useMemo(() => {
    if(!location) return document.location.pathname === '/' ? 'home' : document.location.pathname

    return location.pathname === '/' ? 'home' : location.pathname.substr(1)
  }, [location.pathname])

  return (
    <div className='main' id={`page-${pageId}`}>
      <Header {...props} onLogin={() => setIsLogin(true)} onLogout={() => setIsLogin(false)} isLogin={isLogin} />
      {props.children}
      <Footer />
      <ModalSearch />
      <Scrollup />
    </div>
  )
}

export default Layout
