import type { ConnectResponseData } from '@joyid/ckb'
import type { CellCollector, CellProvider, QueryOptions } from '@ckb-lumos/base'
import type {
  Cell,
  Script,
  WitnessArgs,
} from '@ckb-lumos/lumos'

import { NETWORK } from '../constants'
import { commons } from '@ckb-lumos/lumos'

import {
  getJoyIDCellDep,
  getJoyIDLockScript,
} from '@joyid/ckb'
import { FromInfo, LockScriptInfo } from '@ckb-lumos/common-scripts'
import { blockchain, bytes } from '@ckb-lumos/lumos/codec'
import { addCellDep } from '@ckb-lumos/common-scripts/lib/helper'
import { TransactionSkeletonType } from '@ckb-lumos/lumos/helpers'

import CkbController from './CkbController'

const JOYID_CKB_STORAGE_KEY = 'joyid:ckb::authData'
const JOYID_EVM_STORAGE_KEY = 'joyid:evm::address'

class JoyIdController {
  static #instance: JoyIdController

  #account?: ConnectResponseData
  // #account?: Partial<ConnectResponseData>
  static getInstance(): JoyIdController {
    if (!JoyIdController.#instance) {
      JoyIdController.#instance = new JoyIdController()
    }

    return JoyIdController.#instance
  }

  public loadAccountData() {
    let account = this.account
    let storedData = window.localStorage.getItem(JOYID_CKB_STORAGE_KEY)

    if (!storedData) {
      storedData = window.localStorage.getItem(JOYID_EVM_STORAGE_KEY)

      if (!storedData) return

      account = {
        address: storedData,
        // Filler for now to keep object keys consistent
        alg: -7,
        keyType: 'main_session_key',
        ethAddress: storedData,
        nostrPubkey: '',
        pubkey: '',
        taproot: {
          address: '',
          pubkey: '',
        },
        nativeSegwit: {
          address: '',
          pubkey: '',
        }
      }

    } else {
      account = JSON.parse(storedData)
    }

    this.#account = account
  }

  public set account(account) {
    this.#account = account
  }

  public get account(): ConnectResponseData | undefined {
    return this.#account
  }
}

class JoyIDCellCollector {
  readonly fromScript: Script;
  readonly #cellCollector: CellCollector;

  constructor(
    private fromInfo: FromInfo,
    cellProvider: CellProvider,
    {
      queryOptions = {},
    }: { queryOptions?: QueryOptions }
  ) {
    if (!cellProvider) {
      throw new Error(
        `cellProvider is required when collecting JoyID-related cells`
      );
    }

    if (typeof fromInfo !== 'string') {
      throw new Error(`Only the address FromInfo is supported`);
    }

    const { fromScript } = commons.parseFromInfo(fromInfo, { config: CkbController.config });
    this.fromScript = fromScript;

    queryOptions = {
      ...queryOptions,
      lock: this.fromScript,
      type: queryOptions.type || 'empty',
      data: queryOptions.data || '0x',
    };

    this.#cellCollector = cellProvider.collector(queryOptions);
  }

  async *collect(): AsyncGenerator<Cell> {
    const joyIdMainnetCodeHash = getJoyIDLockScript(true).codeHash;
    const joyIdTestnetCodeHash = getJoyIDLockScript(false).codeHash;

    if (
      !bytes.equal(this.fromScript.codeHash, joyIdMainnetCodeHash) &&
      !bytes.equal(this.fromScript.codeHash, joyIdTestnetCodeHash)
    ) {
      return;
    }

    for await (const inputCell of this.#cellCollector.collect()) {
      yield inputCell;
    }
  }
}

export function getJoyIdScriptInfo(
  connection: ConnectResponseData,
): LockScriptInfo {
  if (connection?.keyType !== 'main_key' && connection?.keyType !== 'sub_key') {
    throw new Error(`Unsupported keyType: ${connection.keyType}, only support main_key or sub_key`)
  }

  const isMainnet = NETWORK === 'mainnet'
  const joyIdLockTemplate = getJoyIDLockScript(isMainnet)
  const joyIdLockInfo: LockScriptInfo = {
    codeHash: joyIdLockTemplate.codeHash,
    hashType: 'type',
    lockScriptInfo: {
      CellCollector: JoyIDCellCollector,
      prepareSigningEntries: () => {
        throw new Error(
          'JoyID doesn\'t support prepareSigningEntries, please do not mix JoyID locks with other locks in a transaction'
        )
      },
      async setupInputCell(txSkeleton: TransactionSkeletonType, inputCell: Cell, _: FromInfo, options: any = {}) {
        const fromScript = inputCell.cellOutput.lock
        if (!bytes.equal(fromScript.codeHash, joyIdLockTemplate.codeHash))
          throw new Error(`The input script is not JoyID script`)
        // add inputCell to txSkeleton
        txSkeleton = txSkeleton.update('inputs', (inputs) =>
          inputs.push(inputCell)
        )

        const output: Cell = {
          cellOutput: {
            capacity: inputCell.cellOutput.capacity,
            lock: inputCell.cellOutput.lock,
            type: inputCell.cellOutput.type,
          },
          data: inputCell.data,
        }

        txSkeleton = txSkeleton.update('outputs', (outputs) =>
          outputs.push(output)
        )

        const since = options.since
        if (since) {
          txSkeleton = txSkeleton.update('inputSinces', (inputSinces) => {
            return inputSinces.set(txSkeleton.get('inputs').size - 1, since)
          })
        }

        const firstIndex = txSkeleton
          .get('inputs')
          .findIndex((input) =>
            bytes.equal(
              blockchain.Script.pack(input.cellOutput.lock),
              blockchain.Script.pack(fromScript)
            )
          )

        if (firstIndex !== -1) {
          while (firstIndex >= txSkeleton.get('witnesses').size) {
            txSkeleton = txSkeleton.update('witnesses', (witnesses) =>
              witnesses.push('0x')
            )
          }

          [getJoyIDCellDep(isMainnet)].forEach((item) => {
            txSkeleton = addCellDep(txSkeleton, item)
          })

          // const lock = parseAddress(connection.address, { config: CkbController.config })

          let newWitnessArgs: WitnessArgs = {
            lock: '0x',
          }

          // CoTA
          // if (connection.keyType === 'sub_key') {
          // const pubkeyHash = bytes
          //   .bytify(utils.ckbHash('0x' + connection.pubkey))
          //   .slice(0, 20)

          // const aggregatorUrl = isMainnet
          //   ? "https://cota.nervina.dev/mainnet-aggregator"
          //   : "https://cota.nervina.dev/aggregator";

          // const aggregator = new Aggregator(aggregatorUrl)

          // const { unlock_entry: unlockEntry } =
          //   await aggregator.generateSubkeyUnlockSmt({
          //     alg_index: 1,
          //     pubkey_hash: bytes.hexify(pubkeyHash),
          //     lock_script: bytes.hexify(blockchain.Script.pack(lock)),
          //   })
          // newWitnessArgs = {
          //   lock: '0x',
          //   outputType: '0x' + unlockEntry,
          // }

          // const cellProvider = txSkeleton.get('cellProvider')
          // if(cellProvider == null) throw new Error('Cell provider is missing while collecting CoTA cell')

          // const cotaCollector = cellProvider.collector({
          //   lock: lock,
          //   type: { ...cotaTypeScriptTemplate, args: '0x' },
          // })

          // const cotaCells: Cell[] = []
          // for await (const cotaCell of cotaCollector.collect()) {
          //   cotaCells.push(cotaCell)
          // }

          // if (!cotaCells || cotaCells.length === 0) {
          //   throw new Error('Cota cell doesn\'t exist')
          // }

          // cotaCells.forEach((cotaCell) => {
          //   const outPoint = cotaCell.outPoint
          //   if (outPoint == null) throw new Error('No outpoint found!')
          //   // note: COTA cell MUST put first
          //   txSkeleton = addCellDep(txSkeleton, {
          //     outPoint,
          //     depType: 'code',
          //   })
          // })
          // }

          const witness = bytes.hexify(
            blockchain.WitnessArgs.pack(newWitnessArgs)
          )
          txSkeleton = txSkeleton.update('witnesses', (witnesses) =>
            witnesses.set(firstIndex, witness)
          )
        }

        return txSkeleton
      },
    },
  }

  return joyIdLockInfo
}

export default JoyIdController.getInstance()
