import { Link } from 'react-router-dom'
import HighlightIcon from '@mui/icons-material/Highlight'

import DragonDobSpotlight from '../../assets/img/dragon-dobs-layer-1-ckb-nervos.webp'
import DragonDobSpotlightMobile from '../../assets/img/dragon-dobs-layer-1-ckb-nervos-mobile.webp'
import NervapeGearSpotlight from '../../assets/img/nervape-gear-nervos-ckb-layer-1.webp'
import NervapeGearSpotlightMobile from '../../assets/img/nervape-gear-nervos-ckb-layer-1-mobile.webp'

import './hero.scss'

const Hero = ({ page }) => {
  return (
    <section className={`hero-section${page ? ` ${page}` : ''}`}>
      <div className='container'>
        <div className="content">
          <img className='symbol' src="/imagiNation-symbol.svg" alt='imagiNation Marketplace - Symbol only Logo' />
          <h1>A world of creativity at your fingertips</h1>
          <h2>Unleash Your Imagination!</h2>
          <h3><Link to='/search'>Create, Buy &amp; Sell Digital Collectibles</Link></h3>
        </div>
      </div>

      <div id="Spotlight">
        <h2><HighlightIcon /><span>Spotlight</span><HighlightIcon /></h2>
        <div className="content">
          <div id="Spotlight--Carousel" className="carousel slide" data-ride="carousel" data-interval="15000" data-pause="hover">
            <ol className="carousel-indicators">
              <li data-target="#Spotlight--Carousel" data-slide-to="0" className="active"></li>
              <li data-target="#Spotlight--Carousel" data-slide-to="1"></li>
              {/* <li data-target="#Spotlight--Carousel" data-slide-to="2"></li> */}
            </ol>
            <div className="carousel-inner">
              {/* SPOTLIGHT #1 */}
              <div className="carousel-item active">
                <div className="collection">
                  <span className="image-wrapper full">
                    <img
                      src={NervapeGearSpotlight}
                      className='d-none d-md-inline'
                      alt="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market" title="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market" loading="lazy" crossOrigin="anonymous" />
                    <img
                      src={NervapeGearSpotlightMobile}
                      className='d-inline d-md-none'
                      alt="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market" title="Nervape Gear on Nervos CKB Layer 1, now on imagiNation Market" loading="lazy" crossOrigin="anonymous" />
                  </span>

                  <h4 className="title sr-only" title="World3 Dragon DOBs">Nervape Gear</h4>

                  <Link
                    to="/collection/nervape-gear"
                    className='btn btn-block btn-success'
                    style={{ fontWeight: 'bold' }}
                  >Explore Nervape Gear!</Link>
                </div>
              </div>
              {/* END SPOTLIGHT #1 */}

              {/* SPOTLIGHT #2 */}
              {/* <div className="carousel-item dragon">
                <div className="collection">
                  <span className="image-wrapper full">
                    <img
                      src={DragonDobSpotlight}
                      className='d-none d-md-inline'
                      alt="Dragon DOBs on Nervos CKB Layer 1, now on imagiNation market" title="Dragon DOBs on Nervos CKB Layer 1, now on imagiNation market" loading="lazy" crossOrigin="anonymous" />
                    <img
                      src={DragonDobSpotlightMobile}
                      className='d-inline d-md-none'
                      alt="Dragon DOBs on Nervos CKB Layer 1, now on imagiNation market" title="Dragon DOBs on Nervos CKB Layer 1, now on imagiNation market" loading="lazy" crossOrigin="anonymous" />
                  </span>

                  <h4 className="title" title="World3 Dragon DOBs">Dragon DOBs</h4>

                  <p className="description">Find yourself a collectible in this immersive Dragon DOB release. Each with many unique traits that will change your adventure in the World3 Realm!</p>

                  <Link
                    to="/collection/dragon-dobs"
                    className='btn btn-block btn-success'
                    style={{ fontWeight: 'bold' }}
                  >Hatch your Dragon!</Link>
                </div>
              </div> */}
              {/* END SPOTLIGHT #2 */}

              {/* SPOTLIGHT #3 */}
              <div className="carousel-item">
                <div className="collection nation">
                  <span className="image-wrapper">
                    <img src="//ipfs.io/ipfs/QmVQM1TePXpdAiWTsRWSatdfxivCcxuysnJnfrq1vKe6VF" alt="" title="" loading="lazy" crossOrigin="anonymous" />
                  </span>

                  <h4 className="title" title="Final Tom-a-bot Battlers">Spore Nation</h4>

                  <div className="description contained">
                    <p>A collection of Spores <em>sprouted</em> by the community. From your stick-man drawings to 3D Renderings, explore the possibilities of CKB with your <strong>imagination</strong> today!</p>

                    <Link
                      to="/collection/0x66f4718d56763e6c5a59f1dcebe46b6bf9ccbb5e57f931ce159203058cef5795"
                      className='btn btn-block btn-danger'
                    >Sprout my own Spore</Link>

                    <p><small></small></p>
                  </div>

                  <span className="image-wrapper extra">
                    <img src="//ipfs.io/ipfs/QmQdWw27xnzhU8Pt3N6Qc7jkXLqSQRNwezbJjF5yPhhGBL" alt="" title="" loading="lazy" crossOrigin="anonymous" />
                  </span>
                </div>
              </div>
              {/* END SPOTLIGHT #3 */}
            </div> {/* end carousel inner */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
