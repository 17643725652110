
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import Modal from 'react-modal'
import { signTransaction as signTransferTx } from '@joyid/ckb'

import {
  APP_KEYS,
  CHAIN_KEYS,
  COTA_REGISTRY_FEE,
  COTA_REGISTRY_PAID_DB_KEY,
  COTA_REGISTRY_RELAY_WALLET,
  CREATE_COLLECTION_STORE_NAME,
  IPFS_URL,
  NFT_STANDARDS,
} from '../../utils/constants'
import { getIpfsHashFromJson, getIpfsHashFromFile } from '../../utils/ipfs'
import { mintGodwokenCollection } from '../../utils/EVM/EvmService'
import {
  checkCotaRegistry,
  mintCotaCollection,
  mintItem as mintCkbItem,
  payCotaRegistryFee,
  registerCotaCell,
} from '../../utils/CKB/CkbService'
import {
  getContractConvertedRoyalty,
  getContractInfo,
  getRenderReadyRoyalty
} from '../../utils/helpers'
import MediaWrapper from '../Media/MediaWrapper'
import { useAuth } from '../../context/authContext'
import NationDB from '../../utils/IndexedDB'

import * as S from './styles'
import './create.scss'

type ChainKeys = typeof CHAIN_KEYS[keyof typeof CHAIN_KEYS]

export const LOCAL_STORAGE_KEY = 'iNation:Create:Collection::selected'

const Collections = (props: {
  user: any
  chain: ChainKeys
  connectAccount: React.MouseEventHandler<HTMLButtonElement> | undefined
  selectedCollection: { [key: string]: any } | null
  setSelectedCollection: (collection: { [key: string]: any } | null) => void
  setSnackBarMessage: (arg0: string) => void
  setOpenSnackbar: (arg0: boolean) => void
}) => {
  const { provider } = useWeb3React()
  const { state } = useAuth()
  const { provider: providerName } = state

  const DEFAULT_COLLECTION = {
    image: '',
    assetType: '',
    banner: '',
    name: '',
    description: '',
    royalty: '',
    isPublic: false,
    isNation: true,
    version: 2,
  }

  const [showCreateCollectionDlg, setShowCreateCollectionDlg] = useState(false)
  const [collections, setCollections] = useState<[{ [key: string]: any }] | []>([])
  const [creatingCollection, setCreatingCollection] = useState(false)
  const [collectionType, setCollectionType] = useState('')
  const [collectionImgUploading, setCollectionImgUploading] = useState(false)
  const [newCollection, setNewCollection] = useState<typeof DEFAULT_COLLECTION & { amount?: number }>(DEFAULT_COLLECTION)
  const [bannerImageUploading, setBannerImageUploading] = useState(false)
  const [store, setStore] = useState<typeof NationDB | null>(null)
  const [cotaRegistered, setCotaRegistered] = useState<null | boolean>(null)
  const [registeringWithCota, setRegisteringWithCota] = useState(false)
  const [ckbAddress, setCkbAddress] = useState('')

  const cotaStatus = useMemo(() => {
    if (registeringWithCota) {
      return <CircularProgress className='loader' />
    } else if (cotaRegistered) {
      return <span>Registered with CoTA Protocol!</span>
    } else {
      const fee = props?.user?.activeWallet === APP_KEYS.joyid ? '155 CKB**' : '150 CKB'
      return <button onClick={() => onRegisterCotaCell()}>
        Register CoTA Cell ({fee})
      </button>
    }
  }, [cotaRegistered, registeringWithCota])

  const {
    address: defaultAddress,
    sporeAddress: defaultSporeAddress
  } = getContractInfo('COMMON_NATION')

  const lastSelectedCollection = useMemo(() => {
    const lastSelected = window.localStorage.getItem(LOCAL_STORAGE_KEY)

    if (lastSelected) {
      return JSON.parse(lastSelected)
    }

    return null
  }, [props.user, provider, providerName, store])

  useEffect(() => {
    let isMounted = true

    async function setupStore() {
      if (!isMounted) return

      const newStore = await NationDB.initDB(CREATE_COLLECTION_STORE_NAME)

      setStore(newStore)
    }

    if (!store) setupStore()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let fetch = true
    if (props?.user) {
      const ckbAddress = props.user.addresses.find((address: { chain: string }) => address.chain === CHAIN_KEYS.ckb).address

      setCkbAddress(ckbAddress)

      async function fetchCotaStatus() {
        let registered = false
        try {
          registered = await checkCotaRegistry(ckbAddress)
        } catch { }

        setCotaRegistered(registered)
      }

      if (fetch) {
        fetchCollections()
        fetchCotaStatus()
      }

    }

    return () => {
      fetch = false
    }
  }, [props?.user, props?.chain, store])

  useEffect(() => {
    if (!props.selectedCollection) return

    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(props.selectedCollection))
  }, [props.selectedCollection])

  const renderCollections = useMemo(() => {
    if (!collections || collections?.length < 1) return null

    return collections
      .sort((a: any, b: any) => {
        // Specify the specific name or address you want to prioritize
        const COMMON_NATION = getContractInfo('COMMON_NATION').address

        if (a.address === COMMON_NATION) {
          return -1
        } else if (b.address === COMMON_NATION) {
          return 1
        } else {
          return 0
        }
      })
      .map((collection: any, index: number) => {
        return (
          <div key={index} onClick={() => props.setSelectedCollection({ [props.chain]: collection })} className={`collection${props.selectedCollection && props.selectedCollection[props.chain]?.address === collection.address ? ' active' : ''}`}>
            <MediaWrapper src={collection.image} />

            <div className="content">
              <h3 className='title'>{collection.name}</h3>
              {renderRoyalty(collection)}
              <S.CollectionType>{collection.standard}</S.CollectionType>
            </div>
          </div>
        )
      })
  }, [collections, props])

  const isCkb = useMemo(
    () => props?.chain === CHAIN_KEYS.ckb,
    [props?.chain]
  )

  const isCota = useMemo(
    () => collectionType === NFT_STANDARDS.cota,
    [collectionType]
  )

  function fetchCollections() {
    if (!props?.user?.address) return

    axios.get(`/collection?owner=${props.user.address}&chain=${props?.chain}`)
      .then((res) => {
        setCollections(res?.data?.collections ?? [])

        res?.data?.collections?.forEach((collection: { [key: string]: any }) => {
          const collectionCheck = collection?.address?.toLowerCase() === defaultAddress || collection?.address?.toLowerCase() === defaultSporeAddress

          const lastSelectedCheck = lastSelectedCollection && (lastSelectedCollection[props.chain]?.address?.toLowerCase() === collection.address?.toLowerCase())

          if (lastSelectedCheck) setSelectedCollection(collection)
          else if (collectionCheck) setSelectedCollection(collection)
        })
      })
      .catch((err) => {
        console.error('err: ', err?.message ?? '')
        setCollections([])
      })
  }

  async function createCollection() {

    if (collectionType === '') {
      props.setSnackBarMessage('Please pick a Collection Type!')
      props.setOpenSnackbar(true)
      return
    } else if (!newCollection.name) {
      props.setSnackBarMessage('Please Input Collection Name!')
      props.setOpenSnackbar(true)
      return
    } else if (!newCollection.image) {
      props.setSnackBarMessage('Please Select Collection Image!')
      props.setOpenSnackbar(true)
      return
    } else if (collectionType === NFT_STANDARDS.cota && (!newCollection?.amount || newCollection.amount < 1)) {
      props.setSnackBarMessage('Please provide an Item Total for CoTA Collection')
      props.setOpenSnackbar(true)
      return
    }

    const royalty = getContractConvertedRoyalty(newCollection)

    try {
      // tmp is for when merged to `main` the old ERROR will work until after the full merge and will become useless
      const { data } = await axios.get(`/collection/exist?name=${newCollection.name}&tmp=tmp`)

      if (data) throw new Error('Similar collection already exists!')

      setCreatingCollection(true)
      setShowCreateCollectionDlg(true)

      if (!props.user.address) return

      try {
        let newAddress
        const name = newCollection.name.trim()
        const description = newCollection.description.trim()
        const ipfsObject: IpfsObject = {
          name,
          description,
          chain: props.chain,
          image: newCollection.image,
          assetType: newCollection.assetType,
          bannerImage: newCollection.banner,
          creator: props.user.addresses.find((address: { chain: string }) => address.chain === props.chain).address,
          royalty,
          isNation: newCollection.isNation,
          standard: collectionType,
          version: newCollection?.version ?? 2,
          createdAt: new Date().getTime()
        }

        let signer
        if (provider && 'getSigner' in provider) signer = await provider?.getSigner()

        if (props.chain === CHAIN_KEYS.godwoken) {
          if (!provider) return

          const ipfsHash = await getIpfsHashFromJson(ipfsObject)

          newAddress = await mintGodwokenCollection(
            newCollection.name,
            IPFS_URL + ipfsHash,
            royalty,
            newCollection.isPublic,
            signer,
            collectionType
          )

          await axios.post(`/sync/Godwoken/collection/${newAddress}`)
        } else if (collectionType === NFT_STANDARDS.spore) {
          ipfsObject.chain = CHAIN_KEYS.ckb
          ipfsObject.description = `[ipfs://${await getIpfsHashFromJson(ipfsObject)}]${description}`

          newAddress = await mintCkbItem(
            { type: collectionType === NFT_STANDARDS.spore ? 'cluster' : NFT_STANDARDS.cota },
            {
              ipfsObject,
              provider: signer,
              address: ckbAddress,
              useLumos: props.user?.useLumos ?? false,
              activeWallet: props.user?.activeWallet ?? providerName ?? null
            }
          )
        } else if (collectionType === NFT_STANDARDS.cota) {
          const ipfsHash = await getIpfsHashFromJson(ipfsObject)

          newAddress = await mintCotaCollection(
            ckbAddress,
            {
              ipfsObject,
              ipfsHash,
              signer,
              amount: newCollection.amount,
              useLumos: props.user?.useLumos ?? false,
              activeWallet: props.user?.activeWallet ?? providerName ?? null
            }
          )
        }

        if (!newAddress) throw new Error('Error creating collection!')


        setCollectionType('')
        setNewCollection(DEFAULT_COLLECTION)
        props.setSnackBarMessage(`Success! Collection Minted at: ${newAddress}`)
        setTimeout(() => fetchCollections(), 5000) // Wait 2 more seconds before fetching again

        props.setOpenSnackbar(true)
        console.info(`New Collection minted at address: ${newAddress}`)

        setCreatingCollection(false)
        setShowCreateCollectionDlg(false)
      } catch (error) {
        setCreatingCollection(false)
        props.setSnackBarMessage(`Transaction failed! ${error?.message}`)
        props.setOpenSnackbar(true)
      }
    } catch (error) {
      // @ts-ignore
      if (error && error?.message) {
        console.error('Error with checking for collection ', error)

        // @ts-ignore
        props.setSnackBarMessage(error.message)
        props.setOpenSnackbar(true)
        return
      } else {
        props.setSnackBarMessage('Collection Name already Exists!')
        props.setOpenSnackbar(true)
      }
    } finally {
      setCreatingCollection(false)
    }
  }

  function handleCollectionImg(event: { target: { files: any[] } }) {
    const fileType = event.target.files[0].type
    // if (fileType === 'image') {
    setCollectionImgUploading(true)
    getIpfsHashFromFile(event.target.files[0]).then((hash) => {
      newCollection.image = `${IPFS_URL + hash}`
      newCollection.assetType = fileType.split('/')[0]

      setNewCollection(prevValues => ({ ...prevValues, ...newCollection }))
      setCollectionImgUploading(false)
    })
    // }
  }

  function handleBannerImage(event: { target: { files: any[] } }) {
    const fileType = event.target.files[0].type.split('/')[0]
    if (fileType === 'image') {
      setBannerImageUploading(true)
      getIpfsHashFromFile(event.target.files[0]).then((hash) => {
        newCollection.banner = `${IPFS_URL + hash}`
        setBannerImageUploading(false)
      })
    }
  }

  function closeCollectionImg() {
    setNewCollection(prevValues => ({ ...prevValues, image: '' }))
    setCollectionImgUploading(false)
  }

  function closeCollectionBannerImage() {
    setNewCollection(prevValues => ({ ...prevValues, banner: '' }))
    setBannerImageUploading(false)
  }

  function renderRoyalty(collection: any) {
    if (!collection && !collection?.royalty) return 0

    return (<div style={{ fontSize: 12 }}>Royalty: {getRenderReadyRoyalty(collection)} %</div>)
  }

  function setSelectedCollection(collection: { [key: string]: any }) {
    if (props?.chain && collection) {
      props.setSelectedCollection({ [props.chain]: collection })
    } else {
      props.setSelectedCollection(null)
    }
  }

  const onRegisterCotaCell = useCallback(async function () {
    if (!props.user) return
    try {
      setRegisteringWithCota(true)
      let signer
      if (provider && 'getSigner' in provider) signer = await provider?.getSigner()

      let paidRegistrationFee = await store?.getData(COTA_REGISTRY_PAID_DB_KEY)

      if (props.user?.activeWallet === APP_KEYS.joyid && !paidRegistrationFee) {
        try {
          const paidFee = await payCotaRegistryFee(props.user)

          paidRegistrationFee = paidFee
          // In case something happens, loosely mark user as paid.
          // The wallet being used as a Relay should be empty via the next TX below.
          store?.updateData(COTA_REGISTRY_PAID_DB_KEY, true)
        } catch (error) {
          console.error('Error collecting CoTA Registry Allocation')
        }
      }

      let registryResult = 'Failed to pay CoTA Registry Fee'

      if (props.user?.activeWallet === APP_KEYS.joyid && !paidRegistrationFee) {
        props.setSnackBarMessage(registryResult)
        props.setOpenSnackbar(true)
        return
      }

      registryResult = 'Failed to register'

      try {
        const registryResponse = await registerCotaCell({
          signer,
          address: ckbAddress,
          useLumos: props.user?.useLumos ?? false,
          activeWallet: props.user?.activeWallet ?? providerName ?? null
        })

        console.log('registryResponse', registryResponse)

        if (!registryResponse) throw new Error(registryResult)

        registryResult = 'Registered with CoTA Protocol!'

        if (typeof registryResponse === 'string') registryResult += ` ${registryResponse}`

        setCotaRegistered(true)
        store?.updateData(COTA_REGISTRY_PAID_DB_KEY, false)
      } catch (error: any) {
        throw new Error(`Error registering CoTA Cell::${error?.message ?? ''}`)
      }

      props.setSnackBarMessage(registryResult)
      props.setOpenSnackbar(true)
    } catch (error: any) {
      props.setSnackBarMessage(error?.message ?? 'Error with CoTA Cell Registry')
      props.setOpenSnackbar(true)
    } finally {
      setRegisteringWithCota(false)
    }
  }, [props, provider, providerName, store])


  return <section id='Create--Collection'>
    <h3 className='title mb-0'>Choose collection</h3>
    {props?.selectedCollection?.address && <span className="mb-3">Collection Address: <strong>{props.selectedCollection.address}</strong></span>}

    <div className='collections'>
      <div className='collection' onClick={() => setShowCreateCollectionDlg(true)}>
        <S.CollectionPlusIcon size={48} />
        <S.CollectionName>Create Collection</S.CollectionName>
      </div>

      {renderCollections}
    </div>

    {/* Create Collection Modal */}
    <Modal
      isOpen={showCreateCollectionDlg}
      onRequestClose={() => {
        setShowCreateCollectionDlg(false)
        props.setSelectedCollection(null)
      }}
      ariaHideApp={false}
      aria={{
        labelledby: 'heading',
      }}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(60, 57, 56, 0.85)',
          zIndex: 999
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: '95vw',
          maxHeight: '95vh',
          borderRadius: '20px',
          backgroundColor: 'rgba(222, 218, 221, 0.975)',
          zIndex: 999,
          padding: 0
        }
      }}
    >
      <S.ModalBody>
        <S.ModalTitle name="heading">
          Create New Collection
        </S.ModalTitle>

        <span style={{ position: 'absolute', top: '0.25rem', right: '1.5rem', fontSize: '1.5rem', color: 'red', cursor: 'pointer' }} onClick={() => setShowCreateCollectionDlg(false)}>X</span>

        <div id='Create--Collection-modal'>
          <div className="upload-wrapper banner">
            <div className='upload' style={{ display: newCollection.banner ? 'none' : '' }}>
              <h3>Banner Image</h3>

              <S.UploadCaption>We recommend an image of at least 1200X400.</S.UploadCaption>
              <S.ChooseFileBtn>
                <span style={{ position: 'relative' }}>Choose File</span>
                <S.FileInput type='file' value='' accept='image/*' onChange={handleBannerImage} />
              </S.ChooseFileBtn>
            </div>
            <div className={`preview-wrapper banner ${newCollection.banner ? 'has-file' : ''}`.trimEnd()}>
              <span className='close-icon' style={{ display: newCollection.banner ? '' : 'none' }} onClick={() => closeCollectionBannerImage()}>&#9760;</span>
              <div className='preview-file'>
                <CircularProgress onClick={() => closeCollectionBannerImage()} className='loader' style={{ display: bannerImageUploading ? '' : 'none' }} />
                {!!newCollection.banner && <MediaWrapper src={newCollection.banner} style={{ display: newCollection.banner ? '' : 'none' }} />}
              </div>
            </div>
          </div>

          <div className="upload-wrapper">
            <div className='upload' style={{ display: newCollection.image ? 'none' : '' }}>
              <h3>Collection Thumbnail</h3>
              <S.UploadCaption>We recommend an image of at least 400X400.</S.UploadCaption>
              <S.ChooseFileBtn>
                Choose File
                <S.FileInput type='file' value='' accept='image/*' onChange={handleCollectionImg} />
              </S.ChooseFileBtn>
            </div>
            <div className={`preview-wrapper nft ${newCollection.image ? 'has-file' : ''}`} style={{ display: newCollection.image ? '' : 'none' }}>
              <span className='close-icon' style={{ display: newCollection.image ? '' : 'none' }} onClick={() => closeCollectionImg()}>&#9760;</span>
              <div className='preview-file'>
                <CircularProgress onClick={() => closeCollectionImg()} style={{ display: collectionImgUploading ? '' : 'none', width: '30px', height: '30px', color: 'red' }} />
                {!!newCollection.image && <MediaWrapper src={newCollection.image} style={{ display: newCollection.image ? '' : 'none' }} onClick={() => closeCollectionImg()} />}
              </div>
            </div>
          </div>

          <S.ModalContent className="details">
            <h3 style={{ textAlign: 'center' }}>Collection Details</h3>
            <S.Field>
              <label>Collection Type</label>
              <S.Select name="CollectionType" id="CollectionType" onChange={(event: { target: { value: React.SetStateAction<string> } }) => setCollectionType(event.target.value)}>
                <option value="false">Choose Collection Type</option>
                {!isCkb ? <>
                  <option value={NFT_STANDARDS.erc721}>{NFT_STANDARDS.erc721}</option>
                  <option value={NFT_STANDARDS.erc1155}>{NFT_STANDARDS.erc1155}</option>
                </>
                  : <>
                    <option value={NFT_STANDARDS.spore}>{NFT_STANDARDS.spore}</option>
                    {/* <option value={NFT_STANDARDS.cota}>{NFT_STANDARDS.cota}</option> */}
                  </>}
              </S.Select>
            </S.Field>

            {collectionType === NFT_STANDARDS.cota && <div className='mb-2 ml-2'>
              <h4 style={{ margin: '0.5rem 0 0.25rem' }}>CoTA Status</h4>
              <span>{cotaStatus} [<a href="https://www.cotadev.io/docs/getting-started/overview" target="_blank" rel="noopener">Learn More</a>]</span>
            </div>}

            <S.Field>
              <label>Name</label>
              <S.Input placeholder='Enter name*' value={newCollection.name} onChange={(event: { target: { value: string } }) => setNewCollection({ ...newCollection, name: event.target.value })} required />
            </S.Field>

            <S.Field>
              <label>Description</label>
              <textarea placeholder='Enter description' value={newCollection.description} onChange={(event: { target: { value: string } }) => setNewCollection({ ...newCollection, description: event.target.value })} />
            </S.Field>

            <S.Field>
              <label>Royalty (%)</label>
              <S.Input type='number' min="0" max="50" step="0.01" placeholder='Enter royalty, maximum is 50%.' value={newCollection.royalty} onChange={(event: { target: { value: string } }) => setNewCollection({ ...newCollection, royalty: event.target.value })} />
            </S.Field>

            {collectionType === NFT_STANDARDS.cota &&
              <S.Field>
                <label>Total Item Amount***</label>
                <S.Input type='number' min="1" step="1" placeholder='Enter Total Items in Collection, e.g. 500' value={newCollection?.amount ?? ''} onChange={(event: { target: { value: string } }) => setNewCollection({ ...newCollection, amount: Number(event.target.value) > 276_447_232 ? 276_447_232 : Number(event.target.value) })} />
              </S.Field>
            }

          </S.ModalContent>
          <div className="footer">
            <h6>*100pCKB fee applied to minting a Collection.</h6>
            {props?.user?.activeWallet === APP_KEYS.joyid && isCota && <h6>**5 CKB added to CoTA Registry as fee and TX coverage</h6>}
            {isCota && <h6>***CoTA NFTs are defined and Items are set-up after defining the Collection, this just creates the Total amount for the collection to Mint. All items share the same defining information, name, description &amp; image. The Max limit is in the hundred millions.</h6>}

            {
              creatingCollection
                ? <button className='btn btn-bordered w-100 mt-sm-4' type='button' onClick={() => createCollection()} >
                  <CircularProgress onClick={() => setCreatingCollection(false)} style={{ width: '16px', height: '16px', color: 'white' }} />
                </button>
                : <button className='btn btn-solid-green w-100 mt-sm-4' onClick={() => createCollection()}>
                  Create collection*
                </button>
            }
          </div>
        </div>
      </S.ModalBody>
    </Modal>
  </section>
}

export default Collections
