import {
  CREATE_COLLECTION_STORE_NAME,
  CREATE_ITEM_STORE_NAME,
  INDEXED_DB_NAME,
  USER_DB_STORE_NAME
} from './constants'

const DB_STORE_LIST = [
  CREATE_ITEM_STORE_NAME,
  CREATE_COLLECTION_STORE_NAME,
  USER_DB_STORE_NAME,
]

class IndexedDB {
  static #version: number = 3
  static #instance: IndexedDB

  #storeName?: string
  #db?: IDBDatabase
  // public request: IDBOpenDBRequest

  private constructor() {

  }

  static getInstance(): IndexedDB {
    if (!IndexedDB.#instance) {
      IndexedDB.#instance = new IndexedDB()
    }

    return IndexedDB.#instance
  }


  public async initDB(storeName: string): Promise<IndexedDB> {
    if (!storeName) throw new Error('Store name required for initialize!')

    const NewDB = IndexedDB.getInstance()

    this.#storeName = storeName

    return new Promise((resolve) => {
      const request = indexedDB.open(INDEXED_DB_NAME, IndexedDB.#version)

      request.onsuccess = (event) => {
        if (!NewDB.#db) NewDB.#db = request.result
        // get current version and store it

        resolve(NewDB)
      }

      request.onerror = (e) => {
        console.error('Error with DB Request')
        resolve(NewDB)
      }

      // if the data object store doesn't exist, create it
      request.onupgradeneeded = (event) => {
        // @ts-ignore
        NewDB.#db = event.target.result

        DB_STORE_LIST.forEach(store => {
          if (NewDB.#db && !NewDB.#db.objectStoreNames.contains(store)) {
            NewDB.#db.createObjectStore(store, { autoIncrement: true })
            // objectStore.createIndex('index', { unique: true })
          }
        })

        resolve(NewDB)
      }
    })
  }

  // Adds a record to the end of the current Store
  public addData = (data: any): void => {
    if (!this.#db || !this.#storeName) return

    const transaction = this.#db.transaction([this.#storeName], 'readwrite')

    transaction.oncomplete = () => {
      console.info('Transaction completed: database modification finished.')
    }

    transaction.onerror = () => {
      console.info(`Transaction not opened due to error: ${transaction.error}`)
    }

    const store = transaction.objectStore(this.#storeName)

    let request
    try {
      request = store?.add(data)

      if (!request) throw new Error('No Store Object Request!')

      request.onsuccess = function (evt) {
        console.info('iNation Local DB Generated', evt)
      }
      request.onerror = function () {
        console.error('iNation Local DB error', this.error)
      }
    } catch (error) {
      throw error
    }
  }

  public deleteData = (key: number) => {
    if (!this.#db || !this.#storeName) return

    const transaction = this.#db.transaction([this.#storeName], 'readwrite')

    transaction.objectStore(this.#storeName).delete(key)

    transaction.oncomplete = () => {
      console.info(`Key "${key}" deleted.`)
    }
  }

  public clearDatabase = () => {
    if (!this.#db || !this.#storeName) return

    const transaction = this.#db.transaction([this.#storeName], 'readwrite')

    transaction.objectStore(this.#storeName).clear()

    // Report that the data item has been deleted
    transaction.oncomplete = () => {
      console.info(`Task Clear complete.`)
    }
  }

  public updateData = <T>(key: number | string, data: T) => {
    if (!this.#db || !this.#storeName) return

    const transaction = this.#db.transaction([this.#storeName], 'readwrite')

    // transaction.objectStore(this.#storeName)

    const store = transaction.objectStore(this.#storeName)
    const request = store.get(key)
    request.onsuccess = () => {
      store.put(data, key)
    }
    request.onerror = (error) => {
      console.error('NationDB::updateData:ERROR -- ', error)
    }
  }

  public getAllStoreData = <T>(): Promise<T[]> => {
    return new Promise((resolve) => {
      if (!this.#db || !this.#storeName) return resolve([])

      let store
      try {
        store = this.#db.transaction([this.#storeName]).objectStore(this.#storeName)
      } catch {
        return resolve([])
      }

      if (!store) return resolve([])

      // resolve()
      const allItems = store.getAll()

      allItems.onsuccess = event => {
        resolve(allItems.result)
      }
    })
  }

  public getData = <T>(key: string): Promise<T | null> => {
    return new Promise((resolve) => {
      if (!this.#db || !this.#storeName) return resolve(null)

      const store = this.#db.transaction([this.#storeName]).objectStore(this.#storeName)

      if (!store) return

      // resolve()
      const item = store.get(key)

      item.onsuccess = () => {
        resolve(item.result)
      }

      // This method allows looping each store item for more control
      // const request = store.openCursor().onsuccess = (event) => {
      //   // this.#db = this.request.result
      //   // @ts-ignore
      //   const cursor = event.target.result
      //   console.log('this.request 1', cursor?.value)
      //   // resolve(cursor?.value)
      //   cursor?.continue()

      //   // res.onsuccess = () => {
      //   //   resolve(res.result)
      //   // }

      //   // res.onerror = (error: any) => {
      //   //   console.error('Error getting Store Object::', error)

      //   //   resolve(error)
      //   // }
      // }
    })
  }
}

export default IndexedDB.getInstance()
