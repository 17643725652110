import React, { useState, useEffect, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Alert, IconButton, Snackbar } from '@mui/material'
import FavoriteBorderTwoToneIcon from '@mui/icons-material/FavoriteBorderTwoTone'
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone'
import axios from 'axios'
import { useAuth } from '../../context/authContext'

import './save.scss'

const SaveNFT = props => {
  const { state } = useAuth()
  const { user } = state
  const { data } = props
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState(<></>)
  const [itemSaved, setItemSaved] = useState(false)
  const [totalSaves, setTotalSaves] = useState(0)

  useEffect(() => {
    if (data?.saves && user?.address && data.saves.find(user => user.address?.toLowerCase() === user?.address?.toLowerCase())) {
      setItemSaved(true)
    }

    setTotalSaves(data?.saves?.length ?? 0)
  }, [data, user?.address])

  const renderTotalSaves = useMemo(() => {
    if (!totalSaves || totalSaves === 0) return ''

    return String(totalSaves)
  }, [totalSaves])

  async function toggleSaveState(event) {
    event.preventDefault()

    if (!user?.address) {
      setSnackBarMessage(<span>Please <strong onClick={props.connectAccount} style={{ textDecoration: 'underline' }}>Connect Wallet</strong> to use the Save feature.</span>)
      setOpenSnackbar(true)
      return
    }

    const { data: response } = await axios.post(`/user/toggle-nft-saved/`, {
      user: user?.address,
      collection: data?.itemCollection ?? data?.address,
      tokenId: data?.tokenId,
      save: data.saved ? !data.saved : false
    })

    setItemSaved(response.saved)
    setTotalSaves(response.savedAmount)
  }

  const handleCloseDialog = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenSnackbar(false)
  }

  return (
    <>
      <span className={`save${itemSaved ? ' saved' : ''}`} onClick={toggleSaveState}>
        {itemSaved ? <FavoriteTwoToneIcon /> : <FavoriteBorderTwoToneIcon />}
        {totalSaves > 0 && <span>{renderTotalSaves}</span>}
      </span>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseDialog}
      >
        <Alert
          severity="warning"
          color="info"
          variant="filled"
          sx={{ fontSize: '1.1rem !important', fontWeight: '500', fontStyle: 'italic' }}
        >
          {snackBarMessage} <IconButton size='small' aria-label='close' color='inherit' onClick={handleCloseDialog}>
            <i className='icon-close' />
          </IconButton>
        </Alert>
      </Snackbar></>
  )
}

export default SaveNFT
