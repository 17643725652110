import { useEffect, useMemo, useState } from 'react'
import { tryAlternateUri } from '../../utils/helpers'

const IPFSImageWrapper = (props: { [key: string]: any }) => {
  const { alt, className, assetType, file, fileType, src, style } = props

  const [altChecked, setAlt] = useState('')
  const [recheckMediaCounter, setRecheckMediaCounter] = useState(0)

  useEffect(() => {
    if (alt && typeof alt === 'string') setAlt(alt)
    if (recheckMediaCounter === 0) setRecheckMediaCounter(1)
  }, [alt])

  function base64ToBlob(base64String: string, fileType: string) {
    if (!base64String) throw new Error('Invalid File for Base64 Conversion!')

    const byteCharacters = Buffer.from(base64String, 'base64')
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters[i]
    }
    // Convert the array of byte numbers to a Uint8Array
    const byteArray = new Uint8Array(byteNumbers)
    // Create a Blob from the Uint8Array
    const blob = new Blob([byteArray], { type: fileType })
    return blob
  }

  const mediaSource = useMemo(() => {
    if (!src) return

    if (file && !src.startsWith('ipfs://')) {
      if (typeof file === 'string') {
        let verifiedFileType = fileType
        if (fileType === 'dob/0') {
          verifiedFileType = 'image/png'
        }

        return URL.createObjectURL(base64ToBlob(file, verifiedFileType))
      } else {
        return URL.createObjectURL(new Blob([new Uint8Array(file.data).buffer], { type: fileType }))
      }
    }

    if (typeof src === 'string') return tryAlternateUri(src, recheckMediaCounter)
    else return ''
  }, [recheckMediaCounter, src])

  const htmlElement = useMemo(() => {
    if (!mediaSource) return null

    switch (assetType) {
      case 'video':
        return <video
          src={mediaSource}
          title={altChecked}
          autoPlay
          loop
          style={{ width: '100%', borderRadius: 10 }}
          onError={() => setRecheckMediaCounter(recheckMediaCounter + 1)}
          crossOrigin="anonymous"
        />
      // Keeping if using srcObject becomes beneficial
      // Current requires rewrapping
      // React & srcObject are not native to each other...
      // https://github.com/facebook/react/issues/11163
      // if (typeof mediaSource === 'string') {
      //   return <video
      //     src={mediaSource}
      //     title={altChecked}
      //     autoPlay
      //     loop
      //     style={{ width: '100%', borderRadius: 10 }}
      //     onError={() => setRecheckMediaCounter(recheckMediaCounter + 1)}
      //     crossOrigin="anonymous"
      //   />
      // } else {
      //   return <video
      //     title={altChecked}
      //     autoPlay
      //     loop
      //     style={{ width: '100%', borderRadius: 10 }}
      //     onError={() => setRecheckMediaCounter(recheckMediaCounter + 1)}
      //     crossOrigin="anonymous"
      //     // @ts-ignore
      //     srcObject={new Blob([mediaSource], { type: fileType })}
      //   />
      // }

      case 'audio':
        return <audio
          src={mediaSource}
          title={altChecked}
          autoPlay
          loop
          controls
          style={{ width: '100%', borderRadius: 10 }}
          onError={() => setRecheckMediaCounter(recheckMediaCounter + 1)}
          crossOrigin="anonymous"
        />

      case 'image':
      default:

        return <img
          src={mediaSource}
          onError={(error) => {
            if (recheckMediaCounter < 6) setRecheckMediaCounter(recheckMediaCounter + 1)
          }}
          alt={altChecked}
          title={altChecked}
          style={style}
          className={`image ${className || ''}`.trimEnd()}
          loading="lazy"
          crossOrigin="anonymous"
        />
    }
  }, [mediaSource])

  return htmlElement
}

export default IPFSImageWrapper
