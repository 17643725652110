import React, { useState, useEffect, useMemo } from 'react'
import DOMPurify from 'dompurify'

import { ACTIVITY_CONSTANTS } from '../../utils/constants'
import { ensureMillisecondsFormat } from '../../utils/helpers'

const ClockItem = (props) => {
  const { auction } = props
  const startTime = useMemo(() => auction.startTime ? ensureMillisecondsFormat(auction.startTime) : 0, [])
  const endTime = useMemo(() => auction.endTime ? ensureMillisecondsFormat(auction.endTime) : 0, [])

  const [auctionStatus, setAuctionStatus] = useState(false)
  const [auctionStatusMessage, setAuctionStatusMessage] = useState('')
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (startTime && endTime) {
        setNewTime()
      } else {
        setAuctionStatusMessage('Auction Inactive')
        setAuctionStatus(false)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const setNewTime = () => {
    const currentTimestamp = new Date().getTime()
    const auctionRunning = currentTimestamp > startTime && currentTimestamp < endTime && auction.type === ACTIVITY_CONSTANTS.AuctionCreated
    let countdownDate = 0

    if (auctionRunning) {
      countdownDate = endTime
      setAuctionStatusMessage('Auction In Progress')
      setAuctionStatus(true)
    } else if (currentTimestamp < startTime && auction.type === ACTIVITY_CONSTANTS.AuctionCreated) {
      setAuctionStatusMessage(`<u>Auction Starting</u> <span>${new Date(startTime).toString()}</span>`)
      setAuctionStatus(false)
    } else if (currentTimestamp > endTime) {
      setAuctionStatusMessage('Auction Ended')
      setAuctionStatus(false)
    } else {
      setAuctionStatusMessage('')
      setAuctionStatus(false)
    }

    if (countdownDate) {
      const distanceToDate = countdownDate - currentTimestamp
      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24))
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      )
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000)

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9]

      if (numbersToAddZeroTo.includes(days)) {
        days = `0${days}`
      }
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`
      }
      if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`
      }
      if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`
      }

      setState({ days, hours, minutes, seconds })
    }
  }

  return (
    <>
      {auctionStatus
        ? <>
          <div className='countdown card no-hover'>
            <div className='countdown-container days'>
              <span className='countdown-heading days-top'>Days</span><span className='countdown-value days-bottom'>{state.days}</span>
            </div>
            <div className='countdown-container hours'>
              <span className='countdown-heading hours-top'>Hours</span><span className='countdown-value hours-bottom'>{state.hours}</span>
            </div>
            <div className='countdown-container minutes'>
              <span className='countdown-heading minutes-top'>Minutes</span><span className='countdown-value minutes-bottom'>{state.minutes}</span>
            </div>
            <div className='countdown-container seconds'>
              <span className='countdown-heading seconds-top'>Seconds</span><span className='countdown-value seconds-bottom'>{state.seconds}</span>
            </div>
          </div>
        </>
        : auctionStatusMessage ? <div className='countdown no-clock card no-hover d-flex text-center'>
          <p style={{ textTransform: 'none' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(auctionStatusMessage) }} />
        </div> : null}
    </>
  )
}

export default ClockItem
