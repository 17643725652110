import React, { useEffect } from 'react'
import { Redirect, BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'
// importing all the themes
import Layout from './layout'
import Home from '../themes/home'
import Authors from '../themes/authors'
import Auctions from '../themes/auctions'
import Collections from '../themes/collections'
import CollectionDetails from '../themes/collectionDetails'
import ListedItems from '../themes/listed-items'
import ItemDetails from '../themes/item-details'
import Activity from '../themes/activity'
import Blog from '../themes/blog'
import BlogSingle from '../themes/blog-single'
import HelpCenter from '../themes/help-center'
import Contact from '../themes/contact'
import Bridge from '../themes/bridge'

import CreatePage from '../themes/create'
import Author from '../themes/author'
import UpdateProfile from '../themes/updateAccount'
import UpdateCollection from '../themes/updateCollection'
import SearchPage from '../themes/search'
import Notifications from '../components/Author/Notifications'
import Admin from '../components/Admin'
import NotFound from '../components/NotFound'
import GroupedCollections from '../components/Collections/Grouped'

function MyRouts(props) {
  return (
    <div>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path='/' exact render={() => (<Layout {...props}><Home {...props} /></Layout>)} />
            <Route path='/explore/authors' exact render={() => (<Layout {...props}><Authors {...props} /></Layout>)} />
            <Route path='/explore/live-auctions' exact render={() => (<Layout {...props}><Auctions {...props} /></Layout>)} />
            <Route path='/explore/listed-items' exact render={() => (<Layout {...props}><ListedItems {...props} /></Layout>)} />
            <Route path='/explore/collections' exact render={() => (<Layout {...props}><Collections {...props} /></Layout>)} />
            <Route path='/collection/:collection' exact render={(routeProps) => (
              <Layout {...props}>
                {routeProps.match.params.collection.startsWith('0x')
                  ? <CollectionDetails {...props} />
                  : <GroupedCollections {...props} />}
              </Layout>)} />
            <Route path='/collections/:collection/:id?' exact render={(routeProps) => {
              const { match } = routeProps
              const { collection, id } = match.params

              if (id) return <Redirect to={`/item-details/${collection}/${id}`} />
              else return <Redirect to={`/collection/${collection}`} />
            }} />
            <Route path='/collection/:collection/:id?' exact render={(routeProps) => {
              const { match } = routeProps
              const { collection, id } = match.params

              if (id) return <Redirect to={`/item-details/${collection}/${id}`} />
              else return <Redirect to={`/collection/${collection}`} />
            }} />
            <Route path='/item-details/:collection' exact render={(routeProps) => {
              const { match } = routeProps
              const { collection } = match.params

              return <Redirect to={`/collection/${collection}`} />
            }} />
            <Route path='/item-details/:collection/:id' exact render={() => (<Layout {...props}><ItemDetails {...props} /></Layout>)} />
            <Route path='/activity' exact render={() => (<Layout {...props}><Activity {...props} /></Layout>)} />
            <Route path='/blog' exact render={() => (<Layout {...props}><Blog {...props} /></Layout>)} />
            <Route path='/blog/:id' exact render={() => (<Layout {...props}><BlogSingle {...props} /></Layout>)} />
            <Route path='/help-center' exact render={() => (<Layout {...props}><HelpCenter {...props} /></Layout>)} />
            <Route path='/contact' exact render={() => (<Layout {...props}><Contact {...props} /></Layout>)} />
            <Route path='/bridge' exact render={() => (<Layout {...props}><Bridge {...props} /></Layout>)} />

            <Route path='/create' exact render={() => (<Layout {...props}><CreatePage {...props} /></Layout>)} />
            <Route path='/account/:address' exact render={() => (<Layout {...props}><Author {...props} /></Layout>)} />
            <Route path='/account/:address/notifications' exact render={() => (<Layout {...props}><Notifications {...props} /></Layout>)} />
            <Route path='/update-account' exact render={() => (<Layout {...props}><UpdateProfile {...props} /></Layout>)} />
            <Route path='/update-collection/:collectionAddress' exact render={() => (<Layout {...props}><UpdateCollection {...props} /></Layout>)} />
            <Route path='/search' exact render={() => (<Layout {...props}><SearchPage {...props} /></Layout>)} />

            <Route path='/admin' exact render={() => (<Layout {...props}><Admin {...props} /></Layout>)} />

            {/* Catch All */}
            <Route render={() => (<Layout><NotFound /></Layout>)} />
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  )
}

export default MyRouts
