import SaveNft from '../Item/Save'
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone'

import './breadcrumb.scss'

const Breadcrumb = (props) => {
  const { featured, itemData, short } = props

  return (
    <>
      <section className={`breadcrumb-area overlay-dark${featured ? ' is-featured' : ''}${short ? ' short' : ''}`}>
        <div className='container'>
          <div className='breadcrumb-content text-center'>
            {featured && <span className="featured-flag"><StarTwoToneIcon /> <span>Featured</span></span>}

            <h1 className='m-0'>{props.title}</h1>
            {itemData?.name && <a href={`https://x.com/intent/tweet?url=${window.location.href}&text=Check out this NFT Collection ${itemData.name} on imagiNation!`} target='_blank' rel="noopener"><svg
              style={{ width: '25px', height: '25px', marginTop: '1rem' }}
              width="1200" height="1227" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white" />
            </svg></a>}

            {itemData && <SaveNft {...props} data={itemData} />}
          </div>
        </div>
      </section>
    </>
  )
}

export default Breadcrumb
